.menu {
    position: fixed;
    top: 0;
    left: -210px; /* Start off-screen */
    width: 210px;
    height: 100%;
    background-color: #424242; /* Background color */
    color: white;
    transition: left 0.2s ease; /* Smooth transition */
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  }
  
  .menu.open {
    left: 0px; /* Slide in when open */
  }
  
  .menu ul {
    list-style: none;
    padding: 20px;
  }
  
  .menu li {
    margin: 15px 0;
  }
  