table {
    border: 1px solid grey;
    border-collapse: collapse;
    margin: 20px auto;
    font-size: 20px;
    width: 500px;
    max-width: 90%;

    th, td {
        border: 1px solid grey;
        border-collapse: collapse;
        padding: 8px 20px;
        text-align: center;
    }

    th {
        color: rgb(107, 106, 106);
        background-color: lightgray;
    }
    
    .editBtn {
        padding: "5px";
        background-color: "green";
        color: "White";
        border: "none";
        border-radius: "3px";
        margin-right: "2px";
     }
    
    .deleteBtn {
        background-color:"red";
        color: "White";
        text-decoration: "none";
        cursor: "pointer";
        padding: "5px";
        border: "none";
        border-radius: "3px";
        margin-left:"2px";
    }
}

.pHead {
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    border: 1px solid lightsteelblue;
    gap: 10px;
    padding: 10px;

    .btnNew {
        border: none;
        background-color: green;
        color: white;
        border-radius: 5px;
        font-size: 20px;
        font-weight: bold;
        height: 40px;
        padding: 8px 15px;
        cursor: pointer;
    }
}

.tagAdmin {
    color: white;
    background-color: lightseagreen;
    border-radius: 4px;
    padding: 3px 5px;
    font-size: 10px;
}

.tagUser {
    color: white;
    background-color: orange;
    border-radius: 4px;
    padding: 3px 5px;
    font-size: 10px;
}

.modalBackground {
    position: fixed; /* Fixed positioning to cover the entire viewport */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    z-index: 1000; /* Ensure it is above other content */
}

.modalContainer {
    background: white; /* White background for the modal */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Shadow for depth */
    width: 350px; /* Fixed width for the modal */
    max-width: 90%; /* Responsive max-width */
    position: relative; /* For positioning of child elements like close button */
    padding: 10px 30px; /* Space around the content */
    margin: 5% auto;
}

.modalTitle {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 5px;
}

.modalInputSection {
    padding: 10px 10px;

    input[type="text"], select {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    
    input[type="number"], select {
        width: 32%;
        padding: 10px;
        box-sizing: border-box;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }
}

.modalBtnSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 15px;
    gap: 20px;

    .modalBtn {
        color: white;
        font-size: 18px;
        font-weight: bold;
        border: none;
        border-radius: 5px;
        padding: 5px 25px;
        cursor: pointer;
    }
}

.modalInsideContainer {
    background: rgb(241, 241, 241); /* White background for the modal */
    width: 290px; /* Fixed width for the modal */
    max-width: 90%; /* Responsive max-width */
    position: relative; /* For positioning of child elements like close button */
    padding: 10px 30px; /* Space around the content */
    margin: 5% auto;
    display: grid;
    grid-template-columns: auto auto;

    .grid-item {
        padding: 5px 0px 0px 0px;
        font-size: 18px;
        text-align: left;
    }

    .grid-item2 {
        padding: 10px 10px 0 0;
        font-size: 18px;
        text-align: right;
    }

}